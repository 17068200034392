/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
/* @import "@angular/material/prebuilt-themes/indigo-pink.css";  */

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  /*background: rgb(7 158 148 / 48%);*/
  /*background: #cdcdcd;*/
  background:#ffff;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
}

/**** Switch *****/
.switch {
  position: relative;
  display: inline-block;
  width: 62px;
  height: 26px;
}

.switch-small {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 18px;
  font-size: 12px;
  margin: 0;
}

.switch input,
.switch-small input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-small .slider:before {
  height: 15px;
  width: 15px;
}

.slider:after {
  content: "OFF";
  position: absolute;
  top: 3px;
  right: 9px;
  left: auto;
  color: #7f7f7f;
}

.switch-small .slider:after {
  top: 2px;
  right: 3px;
}

input:checked + .slider {
  background-color: #4c7770;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4c7770;
}

input:checked + .slider:before {
  transform: translateX(35px);
}

.switch-small input:checked + .slider:before {
  transform: translateX(23px);
}

input:checked + .slider:after {
  content: "ON";
  position: absolute;
  left: 5px;
  color: #ffffff;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/**** Switch Close *****/

table {
  width: 100%;
}

.page-title {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 25px;
  display: inline-block;
  color: #2a4541;
  &:before {
    content: "";
    display: block;
    background: #4b4b4b;
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 80%;
  }
  &:after {
    content: "";
    display: block;
    background: #4b4b4b;
    position: absolute;
    bottom: 6px;
    height: 1px;
    width: 100%;
  }
}

.mat-tab-group {
  background: #ffffff;
}

/*Confirm Box*/

.confirm-dialog-container .mat-dialog-container {
  border-radius: 0.25em 0.25em 0.4em 0.4em;
  padding: 0px;
}

.confirm-dialog-container .content-container {
  margin: 5px 5px 15px 5px;
  color: #808080;
  display: flex;
}

.confirm-dialog-container #close-icon {
  margin-left: auto;
  order: 2;
  font-weight: bolder;
}

.confirm-dialog-container #close-icon:hover {
  cursor: pointer;
}

.confirm-dialog-container #no-button {
  height: 50px;
  width: 50%;
  background-color: #fc7169;
  color: white;
  border-radius: 0px;
}

.confirm-dialog-container #yes-button {
  height: 50px;
  width: 50%;
  background-color: #62969e;
  color: white;
  border-radius: 0px;
}
.confirm-dialog-container #ok-button {
  height: 50px;
  width: 100%;
  background-color: darkgreen;
  color: white;
  border-radius: 0px;
}

.confirm-dialog-container span.content-span {
  padding: 35px 16px;
  text-align: center;
  font-size: 20px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

// custom css

.page-title-header {
  text-align: center;
  width: 100%;
  background-color: #3f585d;
  color: white;
  font-size: medium;
  padding: 3px;
}

.modal-header {
  background-color: #0e5c86 !important;
  color: #ffffff !important;
}

.grid-header {
  font-size: 13px;
}


.mat-header-cell {
  color: #fff !important;
  // background-color: #9ea8a6 !important;
  background-color: #0e5c86 !important;
}
.mat-footer-cell {
  color: black !important;
  // background-color: #9ea8a6 !important;
  /*background-color: #4dc3a3 ;*/
}

mat-header-row {
  line-height: 2.2 !important;
  background-color: #0e5c86 !important;
}

mat-header-row {
  min-height: 20px !important;
  max-height: 45px !important;
}

.table-responsive > mat-table > mat-cell:first-of-type,
.table-responsive > mat-table > mat-footer-cell:first-of-type,
.table-responsive > mat-table > mat-header-cell:first-of-type {
  padding-left: 10px !important;
}

.table-responsive > mat-table > mat-cell:last-of-type,
.table-responsive > mat-table > mat-footer-cell:last-of-type,
.table-responsive > mat-table > mat-header-cell:last-of-type {
  padding-right: 10px !important;
}

.table-responsive > mat-table > mat-footer-row,
.table-responsive > mat-table > mat-row {
  min-height: 20px !important;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6 !important;
}
.table th,
.table td {
  padding: 0.25rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.input-text-css {
  font-size: 12px;
  border-color: #21aaa2;
  background-image: none !important;
  /*  padding-right: 0px;
  padding-left: 8px;*/
  padding-right: 1px !important;
  padding-left: 4px;
}

.font-size-11 {
  font-size: 12px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-15 {
  font-size: 15px !important;
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 18px/32px Roboto, "Helvetica Neue", sans-serif !important;
  margin: 0;
}

.table-td-bgcolor {
  background-color: #afafaf;
}

.mat-raised-button {
  min-width: 64px;
  line-height: 32px !important;
  padding: 0 16px;
}

.text-underling-cursor {
  cursor: pointer !important;
  text-decoration-line: underline !important;
  color: #2a8290 !important;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 2000 !important;
}

.overlay {
  height: 80vh !important;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.table-green,
.table-green > th,
.table-green > td {
  background-color: #0e5c86 !important ;
  color: #ffffff;
}

.mw50 {
  max-width: 50px;
}

.mw100 {
  max-width: 100px;
}

.mw150 {
  max-width: 150px;
}

.mw200 {
  max-width: 200px;
}

.mw250 {
  max-width: 250px;
}

.mw300 {
  max-width: 300px;
}

.mw400 {
  max-width: 400px;
}

.mw500 {
  max-width: 500px;
}
////ck editor style

:root {
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 1em auto;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 50px;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}

/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  background-color: hsl(0, 0%, 97%);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}

/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}

/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}

/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}

/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 1em auto;
  display: table;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}

/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}

/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: "";
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 1em 0;
  display: block;
  min-width: 15em;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
    250ms ease-in-out border;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}

/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}

/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}

/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}

/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}

@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }

  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}
.ck-editor__editable_inline {
  min-height: 500px !important;
}
.required-lable {
  position: relative;

  &::after {
    content: "*";
    color: #ff0303;
    position: absolute;
    top: -4px;
    right: -6px;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.mat-checkbox-layout {
  margin: 0px !important;
}
.check-input {
  position: relative;
  .mat-checkbox {
    position: absolute;
    top: 5px;
    left: 3px;
    .mat-checkbox-inner-container {
      width: 20px;
      height: 20px;
    }
  }
  input {
    padding-left: 40px;
  }
}
.mat-checkbox-frame {
  border-color: #212529;
}
button:focus {
  box-shadow: 0 0 0 0.2rem #007bff40;
}

.voucher-tab {
  background: none;
  .mat-tab-labels{
    column-gap: 5px;
  }
  .mat-tab-label {
    padding: 10px 30px;
    background: #fff;
    color: #000;
    text-decoration: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    opacity: 1;
  }
  .mat-tab-label-active{
    background: #4dc3a3;
    color: #fff !important;
  }
  .mat-ink-bar {
    display: none;
  }
}
.datepicker_feild{
  position: relative;
  .mat-datepicker-toggle{
    position: absolute;
    top: 5px;
    right: 0;
    .mat-icon-button{
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }
}

.table-responsive > mat-table > .mat-row, .table-responsive > .table > tr {
  &:nth-child(odd) {
    background: #b3deda59;
  }
}
.table-responsive > mat-table > .mat-row:hover, .table-responsive > mat-table > .mat-row:focus,
.table-responsive > .table > tr:hover, .table-responsive > .table > tr:focus {
  background-color: #21aaa27d !important;

  .mat-cell {
    /*color: #ffffff;*/
  }

  .arrow-circle {
    color: #FFF;
  }
}
.highlight {
  background-color: #2d8fbc !important;

  .mat-cell {
    color: #ffffff !important;
  }
}
.highlight .arrow-circle {
  color: #FFF !important;
}
.mat-sort-header-arrow, mat-header-arrow {
  color: #fff !important;
}
.add-remove {
  position: relative;
  padding-bottom: 10px;
  //border-bottom: 1px solid #fff;
  padding-right: 35px;
  .remove {
    font-size: 26px;
    outline: none;
    border-radius: 50%;
    margin: 0px;
    background-color: #fff;
    color: #28a745;
    border: 1px solid #28a745;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 20px;
    right: 25px;
    &.no-head{
      top:0px;
    }
  }

  .addmore {
    font-size: 26px;
    outline: none;
    border-radius: 50%;
    margin: 0px;
    background-color: #fff;
    color: #28a745;
    border: 1px solid #28a745;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 20px;
    right: 25px;
    &.both {
      right: 0px;
    }
    &.no-head{
      top:0px;
    }
  }
}

.input-btn {
  position: relative;
  .search {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: #ffff !important;
    color: black;
    margin: 0px;
    border: none;
    bottom: 2px;
    width: 30px;
  }
}
.main-container {
  padding: 40px 0px 40px 0px;
  margin: 20px 0px;
  border-radius: 5px;
  //background: linear-gradient( 45deg,#5dc0b6,transparent);
  background: #c6e4fe;// linear-gradient(to top, #aaa2ea 0%, #5dc0b6 70%, #b7beef 100%);
}
.type-column {
  .type {
    max-width: 50px;
  }
  .feild {
    flex: 1;
  }
}

.fs-12 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.w100 {
  width: 100px;
}
.user-img {
  width: 40px;
  height: 40px;
}
.j-c-center {
  justify-content: center;
}
.d-done {
  display: none;
}
.tab {
  .bdr {
    border: 1px solid #ddd;
    cursor: pointer;
    &.active {
      border: 1px solid #4dc3a3;
      color: #4dc3a3;
    }
  }
}


.addNewPdc {
  outline: none;
  color: #fff;
  background: #4dc3a3;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  width: 25px;
  height: 25px;
}
.input-btn {
  position: relative;
  .search {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: #ffff !important;
    color: black;
    margin: 0px;
    border: none;
    bottom: 2px;
    width: 30px;
  }
}
.tab12 {
  .bdr {
    border: 1px solid #ddd;
    cursor: pointer;
    &.active {
      border: 1px solid #4dc3a3;
      color: #fff;
      background: #4dc3a3;;
    }
  }
}

.top-border {
  border-top: 1px solid #b0abab;
      width: 100%;
}
.bottom-border {
  border-bottom: 1px solid #b0abab;
      width: 100%;
}

.upload {
    p {
      i {
        cursor: pointer;
        margin-left: 5px;
      }
    }
  }
  .fw-7 {
    font-weight: 700;
  }
  .user-img {
    width: 60px;
    height: 60px;
  }
  .fs-12 {
    font-size: 13px;
  }
  .fs-14 {
    font-size: 14px;
  }
  .fs-16 {
    font-size: 16px;
  }
  .w100 {
    width: 100px;
  }
  .j-c-center {
    justify-content: center;
  }
  .customer-content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: hidden;
    overflow-x: auto;
    .c-row {
      flex-wrap: nowrap;
      border-bottom: 1px solid #ddd;
    }
  }
  
  .mw50 {
    max-width: 50px;
  }
  .mw100 {
    max-width: 100px;
  }
  .mw150 {
    max-width: 150px;
  }
  .mw200 {
    max-width: 200px;
  }
  .mw250 {
    max-width: 250px;
  }
  .mw300 {
    max-width: 300px;
  }
  .mw400 {
    max-width: 400px;
  }
  .mw500 {
    max-width: 500px;
  }
  .h-b {
/*    color: #fff !important;
    background-color: #4dc3a3 !important;*/
    padding: 10px;
  }
  .table-border {
    display: flex;
    // flex: 1;
    flex-direction: column;
    // max-width: 95%;
    .item-row {
      display: flex;
      align-items: center;
      border: 1px solid #000;
      border-bottom: none;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 20px;
        flex: 1;
        border-right: 1px solid #000;
        b {
          font-weight: 600;
        }
        input {
          padding: 5px;
          line-height: 1;
          height: auto;
          &.emi-amount {
            width: 150px;
            max-width: 150px;
          }
        }
        &.w50 {
          width: 50px;
          max-width: 50px;
        }
        &.w120 {
          width: 120px;
          max-width: 120px;
        }
        &.w150 {
          width: 150px;
          max-width: 150px;
        }
        &.w200 {
          width: 200px;
          max-width: 200px;
        }
        &.w250 {
          width: 250px;
          max-width: 250px;
        }
        &.w300 {
          width: 300px;
          max-width: 300px;
        }
        &:last-child {
          border-right: none;
        }
      }
      &:last-child {
        border-bottom: 1px solid #000;
      }
    }
  }
  .primary-btn {
    background-color: #28a745;
    color: #ffffff;
    font-size: 12px;
    outline: none;
    border: none;
    border-radius: 4px;
    padding: 2px 10px;
    margin: 0px;
  }

  .fs-12 {
    font-size: 13px;
  }
  .fs-14 {
    font-size: 14px;
  }
  .fs-16 {
    font-size: 16px;
  }
  .w100 {
    width: 100px;
  }
  .user-img {
    width: 40px;
    height: 40px;
  }
  .j-c-center {
    justify-content: center;
  }
  .d-done {
    display: none;
  }
  .highlight {
    background: #f7a9a9; /* green */
  }
.h-b {
/*  color: #fff !important;
  background-color: #4dc3a3 !important;*/
  padding: 10px;

  &.w-b {
    padding: 6px 10px;
  }
  }
  

.modal-content {
  background-color: #F3F3F3 !important;
}

.btn_detail{
  cursor: pointer;
  color: green !important; 
  font-size: 16px
}

.btn_default{
  background-color: #28a745;
  color: #FFFFFF;
  font-size: 12px;
}

.btn_green{
  background-color: #28a745; 
  color: #ffffff; 
  font-size: 12px; 
  line-height: 22px; 
  font-weight: 400; 
  border: none; 
  border-radius: 5px;
}
.btn_delete{
	color:red;
	cursor: pointer;
	font-size: 16px;
}

.formborder {
  /*background: rgba(233, 233, 218, 0.968627451);*/
  /*border-radius: 12px;*/
  padding: 5px !important; // padding: 0px 16px 10px 5px !important; // comment by abinash :: it takes unnecessary gap from bottom will uncomment if needed
  /*box-shadow: 0 3px 6px hsla(0, 0%, 0%, 0.1);*/
  background: rgb(245 253 253);
  border-radius: 12px;
  /*padding: 0.50px 0px 0px !important;*/
  box-shadow: 1px 1px 1px 1px #0e5c86;
  border: 2px solid #21aaa2;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 12px;
  box-shadow: 1px 1px 1px 1px #0e5c86;
  border: 2px solid #21aaa2;
  

}


.button-btn {
  background-color: #0a2e6cb3;
  border: 0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 500;
  outline: 0;
  padding: 16px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: 13px !important;
}

.button-btn:before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 92%;
}

.button-btn:hover {
  box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
  transform: scale(1.0);
  background-color: #2d8fbc;
  color:#ffff;
}

@media (min-width: 768px) {
  .button-btn {
    padding: 9px 30px;
    margin: 0px 0px 2px 3px;
  }
}

/*.btn:hover {
  color: #fff !important;
  text-decoration: none !important;
}*/
.action-btn {
  cursor: pointer;
  color: #086e68;
  font-size: 18px !important;
}

.sidebar-font, .side-bar .side-bar-content .side-bar-menu ul li a span {
  font-size: 13px !important;
}
.mat-header-cell {
  font-size: 13px !important;
  font-weight: 500;
}
.formborder-statement {
  background: rgb(245 253 253);
  border-radius: 12px;
  box-shadow: 1px 1px 1px 1px #0e5c86;
  border: 2px solid #21aaa2;
}

.customer-a:hover {
  color: #d4eae7 !important;
  text-decoration: underline !important;
}
.customer-a {

    color: #6a21aa;
    text-decoration: none;
    background-color: transparent;
    box-shadow: 0 3px 6px hsla(0, 0%, 0%, 0.1);

}
.mat-footer-row {
  font-weight: bold !important;
  background-color: rgb(56 120 117) !important;
  color: #fff !important;
}
.mat-footer-cell {
  color: #fffff9 !important;
}

.heading-text {
  background: #21aaa29e;
  border-radius: 0px;
  padding: 6px;
}
.navbar-text {
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}
.table-responsive > mat-table > .mat-row:hover .mat-cell .customer-a {
  /*color: #ffffff;*/
}


.table-responsive1 > mat-table > .mat-row:nth-child(odd) {
  background: rgb(32 133 206 / 6%);
}
.table-responsive > mat-table > .mat-row:nth-child(odd), .table-responsive > table>tbody > tr:nth-child(odd) {
  background: rgb(32 133 206 / 6%);
}
.box-shadow {
  box-shadow: 1px 1px 1px 1px #0e5c86;
  border: 2px solid #21aaa2;
}
.simple-table thead {
  line-height: 2.2 !important;
  background-color: #0e5c86 !important;
  color: #fff;
}
.simple-table .table {
  background-color: #fff;
}

.simple-table > .table> tbody > tr:nth-child(odd) {
  background: rgba(32, 133, 206, 0.06);
}

.simple-table > .table > tbody > tr > td,
.simple-table > .table > tbody > tr > th,
.simple-table > .table > tbody > tr {
  border: 1px solid rgb(224 224 224);
}

.h-b {
  color: #fff !important;
  background-color: #0e5c86 !important;
  padding: 10px !important;
  border-radius: 5px 5px 0px 0px !important;
}
.mat-paginator {
  display: initial !important;
}
.fs-12{
  font-size: 13px;
  font-weight: 500;
}

td.mat-cell{
  height: 15px;
  font-size: 12px !important;
  padding: 5px;
}

.input-group {
  /* position: absolute !important; */
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100% !important;
}
.search-text {
  width: 100%;
  margin: 3px;
}
.btn:hover {
  color: #ffffff !important;
  text-decoration: none !important;
}
.table-responsive1 {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 12px;
  box-shadow: 1px 1px 1px 1px #0e5c86;
  border: 2px solid #21aaa2;
}
.table-responsive1 .mat-row:hover, .table-responsive1 .mat-row:focus {
  background-color: #21aaa27d !important;

  .mat-cell {
    /*color: #ffffff;*/
  }

  .arrow-circle {
    color: #FFF !important;
  }
}
.mat-cell, .mat-footer-cell {
  font-size: 13px !important;
}
.grid-cell {
  font-size: 13px !important;
}
/*.mat-header-cell, td.mat-cell, td.mat-footer-cell, .table-responsive>.table>td {
  border-bottom-width: 0px !important;
  border-top-width: 0px !important;
}*/
.cursor-style {
  cursor: pointer;
  padding-right: 18px;
  color: #0460b8;
}
.fa-edit:hover {
  color: #212121;
  font-size: 1.3em;
}
.fa-trash:hover {
  color: #212121;
  font-size: 1.3em;
}
.style-delete {
  color: #bb2222;
  cursor: pointer;
  font-size: 1.25em;
}


.button-cover {
/*  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;*/
}

.button-cover:before {
  counter-increment: button-counter;
 
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 124px;
  height: 39px;
  margin: -37px auto 0 auto;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #efebfc;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
#button-1 .knobs:before {
  content: "Active";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 66px;
  height: 40px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 14px 6px;
  background-color: #5d739a;
  border-radius: 12%;
}

#button-1 .checkbox:checked + .knobs:before {
  content: "De-Active";
  left: 63px;
  background-color: #f44336;
}

#button-1 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}

#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
  transition: 0.3s ease all;
}

.min-250{
    min-width:250px;
}
.min-150 {
  min-width: 150px;
}
.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 11px !important;
  line-height: 1 !important;
  border-radius: 0.2rem;
  margin: 2px 0px 3px 0px;
}
mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type {
  padding-right: 0px !important;
}
.mat-tab-group {
  background: none !important;
}
.mat-tab-label {
  height: 20px !important;
  padding: 0px 0px !important;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
  height: 48px;
  padding: 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  opacity: .6;
  min-width: 24% !important;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
  margin: 1px;
  opacity: 1 !important;
  border-top-left-radius: 16px;
  border-top-right-radius: 55px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 51%), 0 2px 4px 1px rgb(187 91 91 / 18%);
}
.mat-tab-label:hover {
  background-color: #6fc6c1;
  color: black;
}
/*.mattab-active {
  background-color :#0e5c86;
  color:#fff;

}*/
/*.mat-tab-header {
  background-color: #00bcd466 !important;
}*/
.mat-tab-label-active {
  background-color: #0e5c86 !important;
  color: #fff !important;
}
.mat-tab-label {
  box-shadow: rgb(53 58 61) 3px 2px 5px, inset rgb(59 89 154 / 9%) -1px 0;
}
element.style {
  /* background-color: #fff; */
}

.multiselect-dropdown {
  background-color: #fff;
}

// #ProductTab 
.mat-tab-label {
  // min-width: 14% !important;
  /*width: 100% !important;*/
  padding-right: 15px !important;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0e5c86 !important;
}
.addmore ,.remove{
  background-color: #fff !important;
  color: #617aa1 !important;
  border: 1px solid #758bad !important;
  }
.Bold-text {
  font-weight: bold;
}
// ::ng-deep .mat-tab-labels {
//   display: grid !important;
//   grid-template-columns: auto auto auto auto auto auto auto !important;
// }

// display: flex;
// flex-direction: row;
// justify-content: space-between;
.theme-green .bs-datepicker-head {
  background-color: #506b97 !important;
}

.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #506b97 !important;
}

.theme-green .bs-datepicker-body table td.week span {
  color: #506b97 !important;
}

.fa-times-circle{
    color:#ffff;
}
.fa-location-dot {
  font-size: 16px !important;
}
